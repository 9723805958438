import { createTheme } from '@mui/material/styles';

import palette from './palette';

const theme = createTheme({
  typography: {
    fontFamily: 'Open Sans, sans-serif',
  },

  palette: {
    ...palette,
  },
});

export default theme;
