import { createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';

import { getNoShowSummary } from './no-show-summary.service';
import { noShowSummaryState } from './no-show-summary.state';
import { convertServiceType } from '@/app/shared/utils/convert-service-type.utils';
import { convertToReal } from '@/app/shared/utils/convert-number-in-brl.utils';

export const noShowSummarySlice = createSlice({
  name: 'no_show_summary',
  initialState: noShowSummaryState,
  reducers: {
    /**
     * @description Open modal
     *
     * @param {Object} state no_show_summary
     * @param {String} payload use to specify which modal to open - tasker_status, warning, incident_details
     *
     * @example
     * // Example 1:
     * openModal('tasker_status');
     *
     * // Example 2:
     * openModal('warning');
     *
     * // Example 3:
     * openModal('incident_details');
     */
    openModal: (state, { payload }) => {
      if (!payload)
        throw Error(
          'Payload is required! Use: tasker_status, warning or incident_details',
        );

      state[payload].open_modal = true;
    },
    /**
     * @description Close modal
     *
     * @param {Object} state no_show_summary
     * @param {String} payload use to specify which modal to close - tasker_status, warning, incident_details
     *
     * @example
     * // Example 1:
     * closeModal('tasker_status');
     *
     * // Example 2:
     * closeModal('warning');
     *
     * // Example 3:
     * closeModal('incident_details');
     */
    closeModal: (state, { payload }) => {
      if (!payload)
        throw Error(
          'Payload is required! Use: tasker_status, warning or incident_details',
        );

      state[payload].open_modal = false;
    },
    /**
     * @description changes the actual incident data in modal
     *
     * @param {Object} state no_show_summary
     * @param {String} payload use to specify which incident to show on modal incident
     *
     * @example
     * // Example 1:
     *  dispatch(noShowSummaryActions.openModalWithSelectedIncicent('61c36d0d664aca0009175be2'));
     */
    openModalWithSelectedIncicent: (state, { payload }) => {
      if (!payload) throw Error('Payload is required! Use the incident id!');

      const currentIncident = state.incidents.find(
        ({ incident_id: incidentId }) => {
          return incidentId === payload;
        },
      );

      const incidentWithFormatedValues = {
        job_date: format(new Date(currentIncident.job_date), 'dd/MM/yyyy'),
        penalty_amount: convertToReal(currentIncident.penalty_amount),
        user_name: currentIncident.user_name,
        service: convertServiceType(currentIncident.service),
        order_number: currentIncident.order_number,
        incident_severity: currentIncident.incident_severity,
      };
      state.incident_details.open_modal = true;
      state.incident_details.current_incident = incidentWithFormatedValues;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(getNoShowSummary.pending, state => {
      state.loading = true;
    });

    addCase(getNoShowSummary.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.incidents = payload?.incidents;
      state.tasker_status.state = payload?.tasker?.state;
      state.progress = payload?.incidents?.length;
      state.first_job_date = payload?.last_jobs?.first_job_date;
      state.incidents_penalties_amount =
        payload?.last_jobs?.incidents_penalties_amount;
      state.jobs_to_leave_suspension =
        payload?.last_jobs?.jobs_to_leave_suspension;
    });

    addCase(getNoShowSummary.rejected, (state, { payload }) => {
      state.loading = false;
      state.error.status = payload?.status;
      state.error.message = payload?.data?.message;
    });
  },
});

export const noShowSummaryActions = noShowSummarySlice.actions;
export const noShowSummarySelector = state => state.no_show_summary;
