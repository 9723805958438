const palette = {
  primary: {
    lighter: '#e6f8f6',
    light: '#4dcea5',
    main: '#1ebea5',
    dark: '#1aaa94',
    contrastText: '#fff',
  },
  secondary: {
    main: '#f44336',
  },
  gray: {
    main: '#676767',
    soft: '#5D5B65',
    dark: '#474747',
    lighter: '#f5f5f5',
    light: '#f9f9f9',
  },
  slateGray: {
    light: '#F1F4F7',
  },
  charcoal: {
    dark: '#070B0D',
  },
  orange: {
    lighter: '#FDF7EC',
  },
};

export default palette;
